import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie/lib/Cookies';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { LinearProgress } from '@mui/material';
import { LogoutControllerApi } from '@apari/core-api';
import { FullScreenLoading, Notification, ApariOfflineStatus } from 'components';
import { AuthenticationContext, AuthenticationProvider } from 'context/AuthenticationContext';
import { AppContext, AppProvider } from 'context/AppContext';
import { NotificationsProvider } from 'context/NotificationsContext';
import { GLOBAL } from 'constants/index';
import { GlobalServices } from 'utils';
import { AppRouter } from 'routers';
import { PUBLIC_ROUTES } from 'routers/AppRouter/PublicRouter';
import setupAxios from 'services/axios';

const logoutController = new LogoutControllerApi();

function App() {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [credentialsLoading, setCredentialsLoading] = useState(false);

    const { authenticated, setAuthToken, setCurrentUser, signOut, userLoading, fetchBookedSubscriptionData } =
        useContext(AuthenticationContext);
    const { loadingBar, notificationState, hideNotifications } = useContext(AppContext);

    const cookie = new Cookies();
    const jwtToken = cookie.get(GLOBAL.JWT_COOKIE_KEY);

    const location = useLocation();
    const navigate = useNavigate();

    const { pushInstruction } = useMatomo();

    pushInstruction('enableJSErrorTracking');

    const checkTokenValidityAndLogoutSession = async () => {
        if (!PUBLIC_ROUTES.includes((location?.pathname as string) || window.location.pathname)) {
            setCredentialsLoading(true);
            await logoutController.shouldLogout();
        }
    };

    useEffect(() => {
        setupAxios(setCredentialsLoading, navigate);
        GlobalServices.getUUID();
    }, []);

    useEffect(() => {
        checkTokenValidityAndLogoutSession();
    }, [location.pathname]);

    useEffect(() => {
        async function checkUserTokenValid() {
            if (jwtToken) {
                try {
                    await setAuthToken(jwtToken);
                } catch (error) {
                    signOut();
                }
            }
            setLoaded(true);
        }

        checkUserTokenValid();
    }, [jwtToken]);

    useEffect(() => {
        async function checkSubscriptionStatus() {
            if (authenticated) {
                await setCurrentUser();
                await fetchBookedSubscriptionData();
            }
        }

        checkSubscriptionStatus();
    }, [authenticated]);

    return (
        <NotificationsProvider>
            <div className="app-loader">{loadingBar && <LinearProgress variant="query" color="primary" />}</div>
            <ApariOfflineStatus />
            {(credentialsLoading || userLoading) && <FullScreenLoading />}
            {loaded && !userLoading && <AppRouter />}
            <Notification {...notificationState} hideNotification={hideNotifications} />
        </NotificationsProvider>
    );
}

const Root = (): React.ReactElement => {
    return (
        <AppProvider>
            <AuthenticationProvider>
                <App />
            </AuthenticationProvider>
        </AppProvider>
    );
};

export default Root;
