import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowBackIcon: React.FC = props => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 16 16" {...props}>
            <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" />
        </SvgIcon>
    );
};

export const NavigationIcon: React.FC = props => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 18 12" {...props}>
            <path d="M0 2V0H18V2H0ZM0 7H18V5H0V7ZM0 12H18V10H0V12Z" />
        </SvgIcon>
    );
};

export const DissatisfiedSadIcon: React.FC<Partial<SvgIconProps>> = props => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path
                d="M15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11Z"
                fill={props.fill || '#5C5C5C'}
            />
            <path
                d="M8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11Z"
                fill={props.fill || '#5C5C5C'}
            />
            <path
                d="M12 14C9.67 14 7.68 15.45 6.88 17.5H8.55C9.24 16.31 10.52 15.5 12 15.5C13.48 15.5 14.75 16.31 15.45 17.5H17.12C16.32 15.45 14.33 14 12 14ZM11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                fill={props.fill || '#5C5C5C'}
            />
        </svg>
    );
};

export const NeutralIcon: React.FC<Partial<SvgIconProps>> = props => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M9 14H15V15.5H9V14Z" fill={props.fill} />
            <path
                d="M15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11Z"
                fill={props.fill || '#5C5C5C'}
            />
            <path
                d="M8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11Z"
                fill={props.fill || '#5C5C5C'}
            />
            <path
                d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                fill={props.fill || '#5C5C5C'}
            />
        </svg>
    );
};

export const SatisfiedHappyIcon: React.FC<Partial<SvgIconProps>> = props => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path
                d="M15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11Z"
                fill={props.fill || '#5C5C5C'}
            />
            <path
                d="M8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11Z"
                fill={props.fill || '#5C5C5C'}
            />
            <path
                d="M12 16C10.52 16 9.25 15.19 8.55 14H6.88C7.68 16.05 9.67 17.5 12 17.5C14.33 17.5 16.32 16.05 17.12 14H15.45C14.75 15.19 13.48 16 12 16ZM11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                fill={props.fill || '#5C5C5C'}
            />
        </svg>
    );
};

export const ExitAndLogoutIcon: React.FC<Partial<SvgIconProps>> = props => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" {...props}>
            <path
                d="M13.8704 6.7037L12.8259 7.74815L13.9963 8.92593H7.94444V10.4074H13.9963L12.8259 11.5778L13.8704 12.6296L16.8333 9.66667L13.8704 6.7037ZM4.98148 4.48148H10.1667V3H4.98148C4.16667 3 3.5 3.66667 3.5 4.48148V14.8519C3.5 15.6667 4.16667 16.3333 4.98148 16.3333H10.1667V14.8519H4.98148V4.48148Z"
                fill="white"
            />
        </svg>
    );
};

export const PaymentIconCircle: React.FC<Partial<SvgIconProps>> = props => {
    return (
        <SvgIcon viewBox="0 0 22 22" fill="none" {...props}>
            <path d="M22 11c0 6.075-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0s11 4.925 11 11z" fill="#fff" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.8 5.645c-.85.364-1.585.692-1.635.729-.173.13-.171.118-.157 1.55.013 1.383.024 1.52.17 2.109.205.827.563 1.46 1.188 2.102.435.447.976.86 1.682 1.284.215.13.258.146.39.146.124 0 .175-.017.322-.105 1.6-.966 2.432-1.863 2.831-3.056.129-.385.248-.995.263-1.344.003-.078.014.125.023.45.009.324.011-.195.005-1.155-.01-1.347-.02-1.765-.047-1.83a.446.446 0 00-.125-.151c-.143-.107-3.164-1.39-3.273-1.39-.062 0-.616.224-1.638.661zM4.743 7.594a.99.99 0 00-.476.366l-.103.153-.015 2.028h3.091c2.931 0 3.09-.003 3.078-.047-.096-.338-.153-.926-.17-1.746l-.016-.786-2.648.003c-1.662.001-2.683.012-2.741.029zm10.812.014c-.086.036-.227.198-.811.927l-.707.883-.304-.45c-.28-.417-.313-.455-.428-.502a.39.39 0 00-.402.044.452.452 0 00-.174.406c.012.063.194.362.501.825.437.656.494.73.597.777.14.063.202.065.344.008.097-.04.224-.186 1.018-1.178.543-.68.919-1.173.939-1.233a.415.415 0 00-.222-.5c-.137-.062-.214-.064-.351-.007zM4.138 16.384l.068.133c.085.168.25.332.418.418l.132.067 6.177.008c4.381.005 6.211-.001 6.295-.022.26-.064.564-.358.61-.589.01-.046.028-.09.04-.097.013-.008.024-1.006.024-2.241 0-1.771-.007-2.224-.034-2.213-.018.008-.118.131-.221.274-.359.495-.986 1.09-1.62 1.537-.674.475-1.104.708-1.395.757-.377.063-.655-.026-1.248-.4-.995-.629-1.769-1.322-2.234-2.003l-.105-.154H4.124l.014 4.525zm0-2.296c0 1.234.003 1.738.007 1.122.004-.617.004-1.626 0-2.243s-.007-.112-.007 1.121zm1.946-.45a.474.474 0 00-.24.37c0 .133.111.304.24.37.118.058.14.06 1.049.06.91 0 .93-.002 1.048-.06a.473.473 0 00.24-.37.474.474 0 00-.24-.37c-.117-.059-.139-.06-1.048-.06-.91 0-.931.001-1.049.06z"
                fill={props.fill}
            />
        </SvgIcon>
    );
};

export const HelpIcon: React.FC<Partial<SvgIconProps>> = props => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 28 28" {...props}>
            <path d="M14.0002 2.3335C13.4168 2.3335 12.8335 2.55516 12.3552 3.02183L3.02182 12.3552C2.10015 13.2652 2.10015 14.7352 3.02182 15.6452L12.3552 24.9785C13.2652 25.9002 14.7352 25.9002 15.6452 24.9785L24.9785 15.6452C25.9002 14.7352 25.9002 13.2652 24.9785 12.3552L15.6452 3.02183C15.1668 2.55516 14.5835 2.3335 14.0002 2.3335ZM14.0002 8.1085C17.1502 8.23683 18.5152 11.4102 16.6602 13.7785C16.1702 14.3618 15.3885 14.7468 14.9918 15.2485C14.5835 15.7502 14.5835 16.3335 14.5835 16.9168H12.8335C12.8335 15.9252 12.8335 15.0968 13.2418 14.5135C13.6268 13.9302 14.4085 13.5802 14.8985 13.1952C16.3335 11.8768 15.9602 10.0218 14.0002 9.87016C13.0435 9.87016 12.2502 10.6518 12.2502 11.6318H10.5002C10.5002 9.6835 12.0752 8.1085 14.0002 8.1085ZM12.8335 18.0835H14.5835V19.8335H12.8335V18.0835Z" />
        </SvgIcon>
    );
};

export const TaxSubmissionIcon: React.FC<Partial<SvgIconProps>> = props => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 20 19" {...props}>
            <path d="M20 19.0002H0V1.0002H2V13.5402L7.5 4.0002L14 7.7802L18.24 0.450195L19.97 1.4502L20 19.0002Z" />
        </SvgIcon>
    );
};

export const ProfileAndSettingsIcon: React.FC<Partial<SvgIconProps>> = props => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 30 30" {...props}>
            <path d="M12.5 5C11.1739 5 9.90215 5.52678 8.96447 6.46447C8.02678 7.40215 7.5 8.67392 7.5 10C7.5 11.3261 8.02678 12.5979 8.96447 13.5355C9.90215 14.4732 11.1739 15 12.5 15C13.8261 15 15.0979 14.4732 16.0355 13.5355C16.9732 12.5979 17.5 11.3261 17.5 10C17.5 8.67392 16.9732 7.40215 16.0355 6.46447C15.0979 5.52678 13.8261 5 12.5 5ZM21.25 15C21.0875 15 20.95 15.1125 20.925 15.2625L20.6875 16.9125C20.3125 17.075 19.95 17.275 19.625 17.5L18.075 16.875C17.9375 16.875 17.775 16.875 17.6875 17.0375L16.4375 19.2C16.3625 19.3375 16.3875 19.5 16.5125 19.6L17.8375 20.625C17.8125 20.8375 17.8 21.0375 17.8 21.25C17.8 21.4625 17.8125 21.6625 17.8375 21.875L16.5125 22.9C16.4 23 16.3625 23.1625 16.4375 23.3L17.6875 25.4625C17.7625 25.625 17.925 25.625 18.075 25.625L19.625 25C19.95 25.225 20.3 25.4375 20.6875 25.5875L20.925 27.2375C20.95 27.3875 21.075 27.5 21.25 27.5H23.75C23.8875 27.5 24.025 27.3875 24.05 27.2375L24.2875 25.5875C24.6625 25.425 25 25.225 25.3375 25L26.875 25.625C27.0375 25.625 27.2 25.625 27.2875 25.4625L28.5375 23.3C28.6125 23.1625 28.575 23 28.4625 22.9L27.125 21.875C27.15 21.6625 27.175 21.4625 27.175 21.25C27.175 21.0375 27.1625 20.8375 27.125 20.625L28.45 19.6C28.5625 19.5 28.6 19.3375 28.525 19.2L27.275 17.0375C27.2 16.875 27.0375 16.875 26.875 16.875L25.3375 17.5C25 17.275 24.6625 17.0625 24.275 16.9125L24.0375 15.2625C24.025 15.1125 23.8875 15 23.75 15H21.25ZM12.5 17.5C6.975 17.5 2.5 19.7375 2.5 22.5V25H14.6C14.0421 23.8285 13.7518 22.5476 13.75 21.25C13.7525 19.9941 14.0254 18.7535 14.55 17.6125C13.8875 17.5375 13.2 17.5 12.5 17.5ZM22.5 19.375C23.5375 19.375 24.375 20.2125 24.375 21.25C24.375 22.2875 23.5375 23.125 22.5 23.125C21.45 23.125 20.625 22.2875 20.625 21.25C20.625 20.2125 21.4625 19.375 22.5 19.375Z" />
        </SvgIcon>
    );
};

export const ApariArrowIcon: React.FC<Partial<SvgIconProps>> = props => {
    return (
        <SvgIcon viewBox="0 0 7 17" fill="none" {...props}>
            <path d="M0 4.01239L3.5572 8.18156L0 12.3507V16.3617L6.98186 8.18156L0 0V4.01239Z" />
        </SvgIcon>
    );
};
